const initialState = {
    CART: {
        cartVariantSummaryList: [],
        noItemsInCart: 0
    },
    menuIndex: null,
    MenuData: []

}
export const cartReducer = (state = initialState.CART, action) => {
    if (action.type === 'CART_CHANGE_ACTION') {
        return action.payload;
    }
    return state;
}
export const mobMenuReducer = (state = null, action) => {
    if (action.type === 'MOB_MENU_ACTION') {
        return action.payload;
    }
    return state;
}
export const mobSearchOpenReducer = (state = true, action) => {
    if (action.type === 'MOB_SEARCH_OPEN') {
        return action.payload;
    }
    return state;
}
export const mobMenuOpenReducer = (state = false, action) => {
    if (action.type === 'MOB_MENU_OPEN') {
        return action.payload;
    }
    return state;
}
export const searchStateReducer = (state = false, action) => {
    if (action.type === 'SEARCH_STATE') {
        return action.payload;
    }
    return state;
}
export const headerTransparentReducer = (state = false, action) => {
    if (action.type === 'HEADER_TRANSPARENT') {
        return action.payload;
    }
    return state;
}
export const videoShowReducer = (state = false, action) => {
    if (action.type === 'VIDEO_SHOW') {
        return action.payload;
    }
    return state;
}

export const MenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'MENU_SWITCH':
            return {
                ...state,
                menuIndex: action.payload
            };
        case 'MENU_NODES':
            return {
                ...state,
                MenuData: action.payload
            };
    }
    return state
}
export const MBCashAvailableReducer = (state = false, action) => {
    if (action.type === 'IS_MBCASH') {

        return action.payload;
    }
    return state;
}

export const userRoleReducer = (state = null, action) => {
    if (action.type === 'USER_ROLE_ACTION') {
        return action.payload;
    }
    return state;
}
