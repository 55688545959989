import Analytics from "./Analytics";
import HK from "./HK";
import RudderStackAnalytics from 'factory/modules/RudderStackAnalytics';
import { __isMobile, __localStorageGet } from "./HK_service";

var UniversalAnalytics = {
	IS_PRESERVE: false,
	PRESERVE_KEY: "PRESERVED_EVENTS",
	PRESERVE_KEY_CLICKSTREAM: "PRESERVED_EVENTS_CLICKSTREAM",
	PRESERVE_KEY_RUDDERSTACK: "PRESERVED_EVENTS_RUDDERSTACK",
	SET_DATALAYER: function (eventName, properties = {}) {
		let _userData = JSON.parse(localStorage.getItem('userData')) || {};
		const _dataLayerObj = {
			moengage_data_layer: {
				pageName: window.pageType,
				userId: _userData.uH,
				email: _userData.uM,
				mobile: _userData.contact,
				gender: _userData.uG,
				...properties,
			},
			event: eventName
		};
		//console.log("_dataLayerObj : ", _dataLayerObj);
		if (this.IS_PRESERVE) {
			this.PRESERVE_EVENTS_TO_STORAGE(_dataLayerObj);
			this.IS_PRESERVE = false;
		} else {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push(_dataLayerObj);
		}
	},
	PRESERVE_EVENTS_TO_STORAGE: function (_dataLayerObj) {
		const _preservedEvents = sessionStorage.getItem(this.PRESERVE_KEY);
		let _preservedEventsParsed = [];
		if (_preservedEvents) {
			_preservedEventsParsed = JSON.parse(_preservedEvents);
		}
		const _newPreservedEvents = [..._preservedEventsParsed, _dataLayerObj];
		sessionStorage.setItem(this.PRESERVE_KEY, JSON.stringify(_newPreservedEvents));
	},
	PRESERVE_CLICKSTREAM_TO_STORAGE: function (eventName, data) {
		const _preservedEvents = sessionStorage.getItem(this.PRESERVE_KEY_CLICKSTREAM);
		let _preservedEventsParsed = [];
		if (_preservedEvents) {
			_preservedEventsParsed = JSON.parse(_preservedEvents);
		}
		let _newEvent = { eventName: eventName, data: data };
		const _newPreservedEvents = [..._preservedEventsParsed, _newEvent];
		sessionStorage.setItem(this.PRESERVE_KEY_CLICKSTREAM, JSON.stringify(_newPreservedEvents));
	},
	PRESERVE_THIRDPARTY_TO_STORAGE: function (eventName, data, thirdPartyKey) {
		const _preservedEvents = sessionStorage.getItem(thirdPartyKey);
		let _preservedEventsParsed = [];
		if (_preservedEvents) {
			_preservedEventsParsed = JSON.parse(_preservedEvents);
		}
		let _newEvent = { eventName: eventName, data: data };
		const _newPreservedEvents = [..._preservedEventsParsed, _newEvent];
		sessionStorage.setItem(thirdPartyKey, JSON.stringify(_newPreservedEvents));
	},
	FIRE_EVENTS_FROM_STORAGE: function () {
		const _preservedEvents = sessionStorage.getItem(this.PRESERVE_KEY);
		if (_preservedEvents) {
			const _preservedEventsParsed = JSON.parse(_preservedEvents);
			window.dataLayer = window.dataLayer || [];
			window.dataLayer = [...window.dataLayer, ..._preservedEventsParsed];
			sessionStorage.removeItem(this.PRESERVE_KEY);
		}
		const _preservedEventsClickStream = sessionStorage.getItem(this.PRESERVE_KEY_CLICKSTREAM);
		if (_preservedEventsClickStream) {
			const _preservedEventsParsed = JSON.parse(_preservedEventsClickStream);
			this.PROCESS_CLICKSTREAM_EVENTS(_preservedEventsParsed);
			sessionStorage.removeItem(this.PRESERVE_KEY_CLICKSTREAM);
		}
		const _preservedEventsRudderstack = sessionStorage.getItem(this.PRESERVE_KEY_RUDDERSTACK);
		if (_preservedEventsRudderstack) {
			const _preservedEventsParsed = JSON.parse(_preservedEventsRudderstack);
			this.PROCESS_THIRDPARTY_EVENTS(_preservedEventsParsed, this.PRESERVE_KEY_RUDDERSTACK);
			sessionStorage.removeItem(this.PRESERVE_KEY_RUDDERSTACK);
		}
	},
	PROCESS_THIRDPARTY_EVENTS: function (eventsDataArr, tpKey) {
		if (eventsDataArr && eventsDataArr.length > 0) {
			for (let eData of eventsDataArr) {
				if (tpKey === this.PRESERVE_KEY_RUDDERSTACK) {
					setTimeout(() => {
						RudderStackAnalytics.SEND_EVENT(eData.eventName, eData.data, true);
					}, 1000);
				}
			}
		}
	},
	PROCESS_CLICKSTREAM_EVENTS: function (eventsDataArr) {
		if (eventsDataArr && eventsDataArr.length > 0) {
			for (let eData of eventsDataArr) {
				if (eData.eventName === 'BuyNow') {
					Analytics.Scope().buyNowAddTocart(eData.data, 'BuyNow');
				} else {
					Analytics.Scope().triggerClickStreamEvent(eData.eventName, eData.data);
				}
			}

		}
	},
	PRESERVE_ON_RELOAD: function () {
		this.IS_PRESERVE = true;
		return this;
	},
	PORDUCT_VIEW_EVENT: function (data, pageType) {
		if (pageType === 'storeVariant' || pageType === 'pack') {
			const isSV = pageType === 'storeVariant' ? true : false;
			let pdpresult = isSV ? data : data.packs;
			let variantImageL = pdpresult.images.length > 0 ? pdpresult.images[0].o_link : "";
			let variantImageM = pdpresult.images.length > 0 ? pdpresult.images[0].m_link : "";
			let packVariantIds = [];
			let allbrands = [];
			if (!isSV) {
				pdpresult.packSv && pdpresult.packSv.map(packitem => {
					packVariantIds.push(packitem.sv_bsc.id);
					allbrands.push(packitem.sv_bsc.brName);
					return '';
				})
			}
			let product_view_item = {
				VariantIds: isSV ? pdpresult.id : packVariantIds.join(','),
				productId: isSV ? 'VRNT-' + pdpresult.id : 'PA-' + pdpresult.id,
				leafNode: isSV ? pdpresult.catName : '',
				categoryName: isSV ? pdpresult.catName : '',
				productName: pdpresult.nm,
				price: pdpresult.mrp,
				offerPrice: pdpresult.offer_pr,
				quantity: 1,
				stockStatus: !pdpresult.oos,
				imageUrl: HK.isMobile() ? variantImageM : variantImageL,
				brandName: isSV ? pdpresult.brTag.brName : allbrands.join(','),
				secondarySategory: isSV ? pdpresult.secondary_category : '',
				contentType: isSV ? 'Variant' : 'Pack',
				vendorName: pdpresult.vendorName || '',
				pageType: pageType,
			}
			let extra_props = {
				navKey: product_view_item.productId,
				variantOfferPrice: product_view_item.offerPrice
			}
			let _dataLayerObj = { ...{ 'pageType': pageType }, ...{ product_view_item: product_view_item }, ...{ 'event': 'reactPageViewMoEngage' }, ...extra_props };
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push(_dataLayerObj);
			RudderStackAnalytics.PREPARE_PRODUCT_EVENT("Product Viewed", {
				total: pdpresult.offer_pr,
				value: "",
				revenue: "",
				product_id: pdpresult.id,
				name: pdpresult.nm,
				category: isSV ? pdpresult.catName : '',
				brand: isSV ? pdpresult.brName : allbrands.join(','),
				price: pdpresult.offer_pr,
				discount: pdpresult.discount,
			}, null);
		}
	},
	LOGIN_EVENT: function (eventName, userData, loginState) {
		let _dataLayerObj = {
			event: eventName,
			hkUserData: userData,
			...userData
		}
		if (loginState) {
			_dataLayerObj.login = true
		} else {
			_dataLayerObj.signup = true
		}
		window.dataLayer = window.dataLayer || [];
		console.log('login/signup _dataLayerObj=======', _dataLayerObj)
		window.dataLayer.push(_dataLayerObj);
		if (eventName === 'reactSignUpEvent' || eventName === 'reactLoginEvent') {
			RudderStackAnalytics.SEND_EVENT('identify', {
				firstLogin: !loginState,
				method: "Google",
				value: 10,
				currency: "INR"
			})
		}
	},
	BUY_NOW_EVENT: function (data) {
		let _dataLayerObj = {
			quantity: data.quantity,
			product: data.nm,
			brand: data.brName || '',
			goal: data.goal || '',
			category: data.primaryCategory || '',
			weight: data.selAttr && data.selAttr['gen-pro-siz'] || '',
			flavor: data.selAttr && data.selAttr['gen-sn-flv'] || '',
			price: data.offer_pr || '',
			item_id: data.id || ''
		}
		this.SET_DATALAYER('QUICK_BUY_CLICK', _dataLayerObj);
		const addToCartBuyNowData = {
			currency: "INR",
			total: data && (data.offer_pr * (data.quantity || 1)) || 0,
			price: data && data.offer_pr || 0,
			value: "",
			revenue: "",
			quantity: data.quantity || 1,
			products: [{
				product_id: data && data.id || "",
				name: data && data.nm || "",
				coupon: "",
				category: data && data.primaryCategory || "",
				brand: data && data.brName || "",
				variant: "",
				price: data && data.offer_pr || 0,
				quantity: data.quantity || 1,
				position: 1,
				currency: "INR",
				discount: data && data.discount || 0,
				item_category2: "",
				item_category3: "",
				item_category4: "",
				item_category5: "",
				item_list_id: "",
				item_list_name: "",
				location_id: ""
			}]
		}
		this.PRESERVE_THIRDPARTY_TO_STORAGE("Product Added", addToCartBuyNowData, this.PRESERVE_KEY_RUDDERSTACK);
		this.PRESERVE_THIRDPARTY_TO_STORAGE("Buy Now", addToCartBuyNowData, this.PRESERVE_KEY_RUDDERSTACK);
	},
	PRODUCT_CLICK_EVENT: function (eventName, data, value) {
		let _dataLayerObj = {
			product: data.nm,
			brand: data.brName || '',
			goal: data.goal || '',
			category: data.catName || '',
			// weight: eventName == 'PRODUCT_WEIGHT_CLICK' ? value : (data.selAttr && (data.selAttr['gen-pro-siz'] || data.selAttr['sn-bar-box']) || ''),
			// flavor: eventName == 'PRODUCT_FLAVOR_CLICK' ? value : (data.selAttr && data.selAttr['gen-sn-flv'] || ''),
			price: data.offer_pr || '',
			// item_id: data.id || ''
		}
		if (eventName == 'PRODUCT_WEIGHT_CLICK') {
			_dataLayerObj = {
				..._dataLayerObj,
				weight: value
			}
		}
		if (eventName == 'PRODUCT_FLAVOR_CLICK') {
			_dataLayerObj = {
				..._dataLayerObj,
				flavor: value
			}
		}
		if (eventName == 'PRODUCT_QUANTITY_CHANGED') {
			_dataLayerObj = {
				..._dataLayerObj,
				quantity: value,
				weight: data.selAttr && ((data.selAttr['gen-pro-siz'] || data.selAttr['sn-bar-box']) || ''),
				flavor: (data.selAttr && data.selAttr['gen-sn-flv'] || ''),
			}
		}
		this.SET_DATALAYER(eventName, _dataLayerObj);
	},
	PAYMENT_MODE_SELECT_EVENT: function (data, newPaymentMode) {
		let name = [], price = [], goal = [], gender = [], category = [], item_brand = [], item_id = [];
		data && data.cartVar && data.cartVar.map((item, i) => {
			price.push(item.totLP || '');
			name.push(item.sv_nm || '');
			goal.push(item.goal || '');
			gender.push(item.gender || '');
			category.push(item.catName || '');
			item_brand.push(item.brand || '');
			item_id.push(item.sv_id || '');
		})
		let eventData = {
			name: name.length > 0 && name.join(' ,') || '',
			price: price.length > 0 && price.join(' ,') || '',
			paymentMode: newPaymentMode.name,
			item_brand: item_brand.length > 0 && item_brand.join(' ,') || '',
			item_id: item_id.length > 0 && item_id.join(' ,') || '',
			category: category.length > 0 && category.join(' ,') || '',
		}
		this.SET_DATALAYER("PAYMENT_MODE_SELECTION", eventData);
	},
	CANCEL_ORDER_EVENT: function (data) {
		let name = [], price = [], goal = [], gender = [], category = [], weight = [], flavor = [], quantity = [];
		let paymentMode = data && data.paymentMode
		data && data.oprLineItems.map((item, i) => {
			let attDetail = item.attrDetail.split(',')
			weight.push(attDetail[0] || '');
			flavor.push(attDetail[1] || '');
			price.push(item.offer_pr || '');
			name.push(item.sv_nm || '');
			goal.push(item.goal || '');
			gender.push(item.gender || '');
			category.push(item.catName || '');
			quantity.push(item.quantity || 1);
		})
		let eventData = {
			name: name.length > 0 && name.join(' ,') || '',
			price: price.length > 0 && price.join(' ,') || '',
			category: category.length > 0 && category.join(' ,') || '',
			paymentMode: paymentMode,
			quantity: quantity.length > 0 && quantity.join(' ,'),
			weight: weight.length > 0 && weight.join(' ,') || '',
			flavor: flavor.length > 0 && flavor.join(' ,') || '',
			pincode: data && data.address && data.address.pin
		}
		this.SET_DATALAYER("CANCEL_ORDER", eventData);
	},
	MENU_CATEGORY_CLICK_EVENT: function (data) {
		let eventData = {
			itemClicked: data
		}
		this.SET_DATALAYER("MENU_CATEGORY_CLICK", eventData);
	},
	SEARCH_CLICK_EVENT: function (searchInput, url, searchSuggestionClick, productName) {
		let eventData = {
			name: searchInput,
			urlFragment: url,
			secondaryCategory: '',
			searchString: searchInput,
			searchSuggestionClick: searchSuggestionClick
		}
		this.SET_DATALAYER("reactSearchEvent", eventData);
		RudderStackAnalytics.SEND_EVENT("Products Searched", {
			query: productName ? productName : searchInput,
		})
	},
	FOOTER_CLICK_EVENT: function (data) {
		let name = data.split(' ').join('_');
		this.SET_DATALAYER(`FOOTER_UL_${name}`);
	},
	WRITE_REVIEW_EVENT: function () {
		this.SET_DATALAYER(`WRITE_REVIEW_PAGE`);
	},
	WRITE_SUCCESSFULL_REVIEW_EVENT: function () {
		this.SET_DATALAYER(`WRITE_SUCCESSFULL_REVIEW`);
	},
	RATING_AND_REVIEW_EVENT: function () {
		this.SET_DATALAYER(`RATING_AND_REVIEW`);
	},
	USER_ACCOUNT_EVENT: function () {
		this.SET_DATALAYER(`USER_ACCOUNT`);
	},
	SELECT_ADDRESS_EVENT: function () {
		this.SET_DATALAYER(`SELECT_ADDRESS`);
	},
	CONTACT_US_EVENT: function () {
		this.SET_DATALAYER(`CONTACT_US`);
	},
	DELIVERY_PINCODE_EVENT: function (pinCode, reviewData) {
		const { nm, brName, goal, catName, selAttr, offer_pr } = reviewData
		const data = {
			pincode: pinCode,
			product: nm,
			brand: brName,
			goal: goal,
			category: catName,
			weight: selAttr && (selAttr['gen-pro-siz'] || selAttr['fn-att-quant']) || '',
			flavor: selAttr && (selAttr['gen-sn-flv'] || selAttr['hfd-flavour']) || '',
			price: offer_pr,
		};
		this.SET_DATALAYER(`DELIVERY_PINCODE`, data);
	},
	OTP_EVENTS: {
		OTP_PAGE: () => {
			UniversalAnalytics.SET_DATALAYER(`OTP_PAGE`);
		},

		OTP_FAILED: () => {
			UniversalAnalytics.SET_DATALAYER(`OTP_FAILED`);
		},

		OTP_SUCCESS: () => {
			UniversalAnalytics.SET_DATALAYER(`OTP_SUCCESS`);
		},

		OTP_REQUESTED: (status) => {
			// status -> 0 -> failed
			// status -> 1 -> success
			UniversalAnalytics.SET_DATALAYER(`OTP_REQUESTED`);
		},
	},
	TRENDING_NOW_AND_FLASH_SALE_EVENTS: function (itemData, isTrendingNow, isFlashSale) {
		let eventName = '';
		if (isTrendingNow) eventName = 'TRENDING_NOW';
		else if (isFlashSale) eventName = 'FLASH_SALE';

		if (itemData && itemData.catItem && eventName) {
			this.PRODUCT_CLICK_EVENT(eventName, itemData.catItem);
		}
	},
	COUPON_CODE_EVENT: function (code, productNamesArray, data) {
		try {
			const getData = (key) => {
				return (data && data.length > 0 && data.map(item => item[key])) || []
			}
			const category = getData('catName');
			const eventData = {
				code: code,
				product: productNamesArray && productNamesArray.length > 0 && productNamesArray.join(','),
				category: category.join(','),
			}
			this.SET_DATALAYER('COUPON_CODE', eventData);
		} catch (error) {
			console.log("ERROR FIRING EVENT : ", error);
		}
	},
	BEST_SELLERS_EVENT: function (itemData) {
		const eventData = {
			category: itemData?.catItem?.catName
		}
		this.SET_DATALAYER('BEST_SELLERS', eventData);
	},
	YOU_MAY_ALSO_LIKE: function () {
		this.SET_DATALAYER('YOU_MAY_ALSO_LIKE');
	},
	HOMEPAGE: function () {
		this.SET_DATALAYER('HOMEPAGE');
	},
	CATEGORY_VIEW: function (eventName) {
		this.SET_DATALAYER(eventName);
	},
	BANNER_CLICK: function (data, position) {
		this.SET_DATALAYER('BANNER_CLICK');
		if (data) {
			this.PROMOTION_EVENT("Promotion Clicked", data, position);
			this.PROMOTION_EVENT("Promotion Viewed", data, position);
		}
	},
	PROMOTION_EVENT: function (eventName, data, position) {
		RudderStackAnalytics.SEND_EVENT(eventName, {
			creative_name: "",
			creative: "",
			creative_slot: "",
			position: position,
			location_id: "",
			promotion_name: data.title,
			name: "",
			promotion_id: data.id,
			products: ""
		}, true)
	},
	ADD_TO_CART: function (data, quantity) {
		RudderStackAnalytics.PREPARE_PRODUCT_EVENT("Product Added", {
			currency: "INR",
			total: data && (data.offerPrice * (quantity || 1)) || 0,
			price: data && data.offerPrice || 0,
			value: "",
			revenue: "",
			quantity: quantity || 1
		}, {
			product_id: data && data.id || "",
			name: data && data.name || "",
			category: data && data.category || "",
			brand: data && data.brName || "",
			price: data && data.offerPrice || 0,
			quantity: quantity || 1,
			discount: data && data.discount || 0
		});
	},
	ADD_TO_WISHLIST: function (data) {
		const isSV = window.pageType === 'storeVariant' ? true : false;
		let allbrands = [];
		if (!isSV) {
			data.packSv && data.packSv.map(packitem => {
				allbrands.push(packitem.sv_bsc.brName);
				return '';
			})
		}
		RudderStackAnalytics.PREPARE_PRODUCT_EVENT("Product Added to Wishlist", {
			currency: "INR",
			total: data.offer_pr,
			price: data.offer_pr,
			value: "",
			revenue: "",
		}, {
			product_id: data.id,
			name: data.nm,
			category: isSV ? data.catName : '',
			brand: isSV ? data.brName : allbrands.join(','),
			price: data.offer_pr,
			discount: data.discount,
		});
	},
	CART_REMOVE: function (data, isPack) {
		RudderStackAnalytics.PREPARE_PRODUCT_EVENT("Product Removed", {
			currency: "INR",
			total: data.totOffPr || 0,
			price: data.totOffPr || 0,
			value: "",
			revenue: "",
			quantity: data.qty || 1,
		}, {
			product_id: isPack ? data.id : data.sv_id || "",
			name: isPack ? data.pckName : data.sv_nm,
			category: isPack ? "" : data.catName,
			brand: isPack ? "" : data.brand,
			price: data.totOffPr || 0,
			quantity: data.qty || 1,
			discount: isPack ? data.pack_discount : data.discount
		});
	},
	PLP_VIEWED: function (data, pgSeo, navKey) {
		if (data.variants && data.variants.length > 0) {
			RudderStackAnalytics.PREPARE_PRODUCT_EVENT("Product List Viewed", {
				list_id: navKey || "",
				category: pgSeo && pgSeo.h1 || "",
			}, {
				product_id: data.variants[0].id,
				name: data.variants[0].name,
				category: data.variants[0].catName || '',
				brand: data.variants[0].brName || "",
				price: data.variants[0].offerPrice,
				discount: data.variants[0].discount
			});
		}
	},
	SHARE_CLICK: function (data, pageType, shareUrl) {
		const isSV = pageType === 'storeVariant' ? true : false;
		let pdpresult = isSV ? data : data.packs;
		let allbrands = [];
		if (!isSV) {
			pdpresult.packSv && pdpresult.packSv.map(packitem => {
				allbrands.push(packitem.sv_bsc.brName);
				return '';
			})
		}
		RudderStackAnalytics.PREPARE_PRODUCT_EVENT("Product Shared", {
			share_via: shareUrl,
			content_type: "",
			item_id: "",
			product_id: pdpresult.id,
			sku: pdpresult.navKey || "",
		}, {
			product_id: pdpresult.id,
			name: pdpresult.nm,
			category: isSV ? pdpresult.catName : '',
			brand: isSV ? pdpresult.brName : allbrands.join(','),
			price: pdpresult.offer_pr,
			discount: pdpresult.discount,
		});
	},
	VIEW_SEARCH_RESULTS: function (search_term, data) {
		let productVariants = {}
		if (data.variants && data.variants.length > 0) {
			productVariants = {
				product_id: data.variants[0].id,
				name: data.variants[0].name,
				category: data.variants[0].catName || '',
				brand: data.variants[0].brName || "",
				price: data.variants[0].offerPrice,
				discount: data.variants[0].discount,
			}
		} else {
			productVariants = {
				product_id: "NOT_FOUND",
				name: "NOT_FOUND",
			}
		}
		RudderStackAnalytics.PREPARE_PRODUCT_EVENT("view_search_results", {
			search_term: search_term
		}, productVariants);
	},
	PRODUCT_CLICK: function (eventSource, data) {
		RudderStackAnalytics.PREPARE_PRODUCT_EVENT("Product Clicked", {
			list_id: "",
			category: "",
			eventSource: eventSource,
			product_id: data.id,
			name: data.nm,
			category: data.catName || '',
			brand: data.brName || "",
			price: data.offer_pr,
			discount: data.discount,
		}, null);
	},
	PRODUCT_ARRAY: function (dataSv, dataPk) {
		let productVariants = [];
		let position = 0;
		if (dataSv) {
			dataSv.map((item) => {
				position++;
				let variant = {
					product_id: item.sv_id,
					name: item.sv_nm,
					coupon: "",
					category: item.catName,
					brand: item.brand,
					variant: "",
					price: item.totOffPr,
					quantity: item.qty,
					position: position,
					currency: "INR",
					discount: item.discount,
					item_category2: "",
					item_category3: "",
					item_category4: "",
					item_category5: "",
					item_list_id: "",
					item_list_name: "",
					location_id: ""
				}
				productVariants.push(variant);
			})
		}
		if (dataPk) {
			dataPk.map((item) => {
				position++;
				let allbrands = [];
				item.variants && item.variants.map(packitem => {
					allbrands.push(packitem.brand);
					return '';
				})
				let variant = {
					product_id: item.id,
					name: item.pckName,
					coupon: "",
					category: "",
					brand: allbrands,
					variant: "",
					price: item.totOffPr,
					quantity: item.qty,
					position: position,
					currency: "INR",
					discount: item.pack_discount,
					item_category2: "",
					item_category3: "",
					item_category4: "",
					item_category5: "",
					item_list_id: "",
					item_list_name: "",
					location_id: ""
				}
				productVariants.push(variant);
			})
		}
		return productVariants;
	},
	CART_VIEWED: function (data) {
		const productVariants = this.PRODUCT_ARRAY((data.cartVar && data.cartVar.length > 0) ? data.cartVar : null, (data.cartPacks && data.cartPacks.length > 0) ? data.cartPacks : null);

		RudderStackAnalytics.SEND_EVENT("Cart Viewed", {
			currency: "INR",
			total: data.totPay || 0,
			value: "",
			revenue: "",
			products: productVariants
		}, true)
	},
	CHECKOUT_STARTED: function (data) {
		const productVariants = this.PRODUCT_ARRAY((data.cartVar && data.cartVar.length > 0) ? data.cartVar : null, (data.cartPacks && data.cartPacks.length > 0) ? data.cartPacks : null);
		RudderStackAnalytics.SEND_EVENT("Checkout Started", {
			currency: "INR",
			total: data.totPay,
			value: "",
			revenue: "",
			coupon: "",
			products: productVariants
		}, true)
	},
	PAYMENT_ENTERED: function (data, method) {
		const productVariants = this.PRODUCT_ARRAY((data.cartVar && data.cartVar.length > 0) ? data.cartVar : null, (data.cartPacks && data.cartPacks.length > 0) ? data.cartPacks : null);
		RudderStackAnalytics.SEND_EVENT("Payment Info Entered", {
			currency: "INR",
			total: data.totPay,
			value: "",
			revenue: "",
			coupon: "",
			payment_method: method,
			products: productVariants
		}, true)
	},
	ORDER_COMPLETED: function (data) {
		const productVariants = this.PRODUCT_ARRAY((data.orderPlacedDetails.orderVar && data.orderPlacedDetails.orderVar.length > 0) ? data.orderPlacedDetails.orderVar : null, (data.orderPlacedDetails.packVar && data.orderPlacedDetails.packVar.length > 0) ? data.orderPlacedDetails.packVar : null);
		RudderStackAnalytics.SEND_EVENT("Order Completed", {
			currency: "INR",
			total: data.orderPlacedDetails.totPay,
			value: "",
			revenue: "",
			coupon: "",
			order_id: data.orderPlacedDetails.gId,
			tax: "",
			shipping: "",
			affiliation: "",
			products: productVariants
		}, true)
	},
	CHECKOUT_COMPLETED: function (data) {
		const productVariants = this.PRODUCT_ARRAY((data.cartVar && data.cartVar.length > 0) ? data.cartVar : null, (data.cartPacks && data.cartPacks.length > 0) ? data.cartPacks : null);

		RudderStackAnalytics.SEND_EVENT("Checkout Step Completed", {
			currency: "INR",
			total: data.totPay,
			value: "",
			revenue: "",
			coupon: "",
			shipping_method: "",
			products: productVariants
		}, true)
	},
	OLEPDATASUBMIT: function (eventName, eventData) {
		this.SET_DATALAYER(eventName, eventData);
	},
	refer_and_earn: function (data) {
		let _userData = __localStorageGet('userData') || {};
		const _dataLayerObj = {
			referAndEarn_data_layer: {
				pageName: window.pageType,
				user_id: _userData && _userData.uH || '',
				email: _userData && _userData.uM || '',
				mobile: _userData && _userData.contact || '',
				platform: __isMobile() ? 2 : 1,
				device: __localStorageGet('deviceId'),
				is_active_user: true,
				tot_redeem_pts: _userData && _userData.tot_redeem_pts || '',
				referalPoints:0,
				...data,
			},
			event: 'reactReferAndEarn'
		};
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(_dataLayerObj);
	},

	LoyaltyDataLayer: function (data) {
		let _userData = __localStorageGet('userData') || {};
		const _dataLayerObj = {
			loyalty_data_layer: {
				pageName: window.pageType,
				user_id: _userData && _userData.uH || '',
				email: _userData && _userData.uM || '',
				mobile: _userData && _userData.contact || '',
				platform: __isMobile() ? 2 : 1,
				device: __localStorageGet('deviceId'),
				is_active_user: true,
				tot_redeem_pts: _userData && _userData.tot_redeem_pts || '',
				referalPoints:0,
				...data,
			},
			event: 'Loyalty'
		};
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(_dataLayerObj);
	}
}
export default UniversalAnalytics;