import 'static/css/index.scss';
import 'static/css/mb.scss';
import 'static/css/auth.scss';
import 'static/css/global/normalize.scss';
import 'static/css/global/nextjs_global.scss';
import 'static/css/global/skeleton.scss';

import 'static/css/global/home_global.scss';
import 'static/css/checkout/checkoutFlow.scss';
//need to modulize

import 'react-date-picker/dist/DatePicker.css';
import "swiper/swiper-bundle.min.css";
import React, { useEffect, useState } from "react";
import { Provider } from 'react-redux';
import { useStore } from 'factory/store';
import { useRouter } from 'next/router';
import { getPageNameFromUrl } from 'factory/utils/clientUtils';
import HK from 'factory/modules/HK';

export default function MyApp({ Component, pageProps }) {

  const [loading, setLoading] = useState(false);
  const [pageName, setPageName] = useState("");
  const [winVars, setWinVars] = useState(false);

  const router = useRouter();
  const store = useStore(pageProps.initialReduxState);

  if(typeof window != 'undefined' && !winVars){
    setWinVars(true);
		console.log("WIN VARS INIT");
    window.__analytics = {
      aws: false,
      criteo: false,
      standBy: {
        aws: [],
        criteo: []
      }
    };
  }

  useEffect(() => {

    if ("serviceWorker" in navigator) {
      window.addEventListener("load", function () {
        navigator.serviceWorker.register("/sw.js").then(
          function (registration) {
            console.log("Service Worker registration successful with scope: ", registration.scope);
          },
          function (err) {
            console.log("Service Worker registration failed: ", err);
          }
        );
      });
    }
    if (HK.getSearchParmas().token) {
      checkForceLogin(HK.getSearchParmas().token);
    }

  }, []);

  useEffect(() => {
    const handleStart = (url) => {
      // console.log(`Loading: ${url}`)
      let _pageName = getPageNameFromUrl(url);
      setPageName(_pageName);
      setLoading(true);
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      if(!window.__RI){
        window.__RI = new Date();
      }
      // NProgress.start()
    };
    const handleStop = () => {
      setLoading(false);
      // NProgress.done()
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);

  const checkForceLogin = (token) => {
    if (!HK.loggedIn()) {
      HK.checkForceLogin(token).then(() => {
      }).catch(err => {
        HK.errorPopup("Something went wrong, please try again.");
        HK.reportErrorToDashboard({
          stackTrace: new Error().stack,
          errorMessage: err
        });
      });
    }
  };
  return <Provider store={store}>
    <div className="hk-app">
      <Component {...pageProps} routeLoading={loading} pageName={pageName}/>
      {/* {
        loading && (typeof window != 'undefined' && !['category','brandCatalog','menuLanding','bestseller','clearance'].includes(window.pageType))&& <div className="hk-loader-default"><div></div></div>
      } */}
    </div>
  </Provider>;
}
